<!-- 入库认领 -->
<template>
    <div class="asnBill">
        <div ref="commonSearchRef">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane
                v-for="(item, index) in adoptStatus" :key="index"
                :label="item.label" :name="item.value">
                </el-tab-pane>
            </el-tabs>
            <div class="publicSearch p-10">
                <div class="searchBar" :style="{ height: isMoreSearch ? 'auto' : '' }">
                    <div>
                        <!-- <span>入库单单号</span> @dblclick.native="handleDblclick" -->
                        <el-input style="width: 350px;" v-model="searchData.keyWord" size="mini" placeholder="认领单号、物流单号搜索，支持输入空格批量搜索" clearable></el-input>
                    </div>
                    <div v-if="activeName === 'all'">
                        <!-- <span>入库状态</span> -->
                        <el-select v-model="searchData.state" size="mini" filterable placeholder="入库状态" clearable>
                            <el-option v-for="(item, index) in adoptStatus" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div v-if="activeName == 1">
                        <div style="display: flex; align-items: center;">
                            <el-select size="mini" v-model="searchData.selectTime">
                                <el-option :label="$t(`m.common.creation_time`)" value="createTimeList"></el-option>
                                <!-- <el-option label="出库时间" value="outTime"></el-option> -->
                            </el-select>
                            <el-date-picker class="ml5"
                                v-model="searchData[searchData.selectTime]"
                                size="mini"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            ></el-date-picker>
                        </div>
                    </div>
                    <div>
                        <el-button @click="getList" size="mini" icon="el-icon-search" type="primary">查询</el-button>
                        <el-button @click="clearSearchData" size="mini" icon="el-icon-refresh">重置</el-button>
                    </div>
                </div>
                <!-- <span class="moreSearchBtn" :class="isMoreSearch ? 'el-icon-caret-top' : 'el-icon-caret-bottom'" @click="isMoreSearch = !isMoreSearch"></span> -->
            </div>
            <div class="headerBtns">
            </div>
        </div>
        <div class="tableBox">
            <el-table
                ref="singleTable"
                tooltip-effect="dark myTooltips"
                :row-style="$parent.tableRowStyle"
                highlight-current-row
                stripe
                border
                :header-cell-style="$parent.tableHeaderColor"
                v-loading="loading.masterTable"
                :data="asnBills"
                @selection-change="handleSelectionChange"
                style="width: 100%"
                row-key="billNo"
                :expand-row-keys="expandRowKeys"
                :height="dynamicsHeight(commonSearchRef, true, true, 10)"
                @expand-change="(a,b) => handleExpandChange(a,b, 'item')"
            >
                <el-table-column type="selection" width="55" key="0" fixed="left"></el-table-column>
                <!-- <el-table-column type="index" label="序号" width="50"> </el-table-column> -->
                <el-table-column show-overflow-tooltip prop="claimNo" label="认领单号" width="170" key="1"> </el-table-column>
                <el-table-column show-overflow-tooltip prop="trackingNumber" label="物流单号" width="170" key="2"> </el-table-column>
                <el-table-column show-overflow-tooltip prop="state" label="状态" width="80" key="3" v-if="activeName==='all'">
                    <template slot-scope="scope">
                        <el-tag :type="adoptStatus.find(item => Number(item.value) == scope.row.state)?.type" v-if="scope.row.state||scope.row.state===0">
                            {{ adoptStatus.find(item => Number(item.value) == scope.row.state)?.label }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="returnUdfHs1" label="收货备注" min-width="all" key="4"></el-table-column>
                <el-table-column prop="imageUrl" label="收货图片" min-width="all" key="5" width="170">
                    <template slot-scope="scope">
                        <div v-if="scope.row.imageUrl">
                            <el-image class="ml5" v-for="(imgItem, imgIndex) in getImgList(JSON.parse(scope.row.imageUrl))" :key="imgIndex"
                            style="width: 50px; height: 50px;" :src="imgItem" :preview-src-list="JSON.parse(scope.row.imageUrl)"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <template v-if="activeName == 2 || activeName == 'all'">
                    <el-table-column show-overflow-tooltip prop="billNo" label="关联单号" width="170" key="6"> </el-table-column>
                    <el-table-column show-overflow-tooltip prop="billSource" label="新增类型" key="13" v-if="activeName!=='all'">
                        <template slot-scope="scope">
                            <div> {{ scope.row.billSource == 1 ? '退件入库':'-' }} </div>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip prop="claimUserName" label="认领人" key="8"> </el-table-column>
                </template>
                <template v-if="activeName == 'all'">
                    <el-table-column show-overflow-tooltip prop="billSource" label="单据类型" key="7">
                        <template slot-scope="scope">
                            <div> {{ scope.row.billSource == 1 ? '退件入库':'-' }} </div>
                        </template>
                    </el-table-column>
                </template>
                <el-table-column  show-overflow-tooltip prop="time" label="相关时间" key="14">
                    <template slot-scope="scope">
                        <span v-if="activeName == 2 || activeName == 'all'">认领时间: {{ scope.row.claimTime||'-' }}</span><br>
                        <span>创建时间: {{ scope.row.createTime||'-' }}</span><br>
                        <span v-if="activeName == 3 || activeName == 'all'">报废时间: {{ scope.row.cancelTime||'-' }}</span><br>
                    </template>
                </el-table-column>
              <template v-if="activeName == 'all' || activeName == 3">
                <el-table-column show-overflow-tooltip prop="cancelUserName" label="报废人" key="12"> </el-table-column>
              </template>
              <template v-if="activeName == 1 || activeName == 2">
                <el-table-column :label="$t(`m.common.operation`)" fixed="right" min-width="all">
                  <template slot-scope="scope">
                    <!-- 待认领 -->
                    <el-button type="text" v-if="scope.row.state == 1" @click="deleteBtn(scope.row)">报废</el-button>
                    <!-- 已认领 -->
                    <el-button type="text" v-if="scope.row.state == 2" @click="viewProduct(scope.row)">查看产品</el-button>
                  </template>
                </el-table-column>
              </template>
            </el-table>
            <el-pagination
                class="pagination-bottom"
                style="text-align: right"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="searchData.pageNum"
                :page-sizes="[5, 10, 15, 20, 25]"
                :page-size="searchData.pageSize"
                layout="sizes,total, prev, pager, next"
                :total="searchData.total"
            ></el-pagination>
        </div>
        <el-dialog title="备注" :visible.sync="memoVisible" width="30%">
            <el-form :model="rowData">
                <el-form-item label="订单号" :label-width="formLabelWidth">
                    <span>{{ memoData.billNo }}</span>
                </el-form-item>
                <el-form-item :label="$t(`m.common.remark`)" :label-width="formLabelWidth">
                    <el-input style="width:350px" type="textarea" rows="4" maxlength="200" show-word-limit v-model="memoData.memo" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="memoVisible = false">取 消</el-button>
                <el-button type="primary" @click="commitMemo">确 定</el-button>
            </div>
        </el-dialog>
        <Tip ref="myTip" :title="tipObj.title" :content="tipObj.content" @submit="handleTipSubmit"></Tip>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash'
import { formatListDataByKey, dynamicsHeight } from '@/utils/DateUtils.js'
import Tip from '@/components/ZhiHuiCompon/Tip.vue';
import Tooltip from '@/components/ZhiHuiCompon/Tooltip.vue';

export default {
    name: 'adoptClaim',
    components: {
        Tip, Tooltip
    },
    data() {
        return {
            commonSearchRef: null,
            searchRadio: '',
            queryForm: {},
            clientHeight: $(window).height(),
            noShipVisible: false,
            noShipInfo: {},
            memoData: {},
            memoVisible: false,
            asnPrintCode: 10,
            wlVisible: true,
            isMoreSearch: false,
            wareId: PF.getLocal('wareId'),
            loading: {
                masterTable: true,
            },
            rowData: {},
            carrierCode: null,
            searchData: {
                // billNoType: 1, //搜索单据类型:1入库单2关联单号
                pageNum: 1,
                pageSize: 15,
                wareId: PF.getLocal('wareId'),
                wareCode: PF.getLocal('wareCode'),
                state: null,
                keyWord: '',
                selectTime: 'createTimeList'
            },
            asnBills: [],
            platforms: [],
            carriers: [],
            formLabelWidth: '120px',
            asnUdfHs: [],
            organizations: [],
            suppliers: [],
            multipleSelection: [],
            asnTypes: [],
            billSourceOptions: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '0',
                    label: '采购单',
                },
                {
                    value: '1',
                    label: '新建',
                },
                {
                    value: '2',
                    label: 'ERP导入',
                },
                {
                    value: '4',
                    label: '调拨',
                },
            ],
            activeName: 'all',
            expandRowKeys: [],
            tipObj: {
                show: false,
                title: '提示',
                type: '',
                content: ''
            },
        };
    },
    watch: {
        carrierCode(newVal, oldVal) {
            this.searchData.carrierCode = newVal;
            this.getList();
        },
    },
    computed: {
        dynamicsHeight,
        ...mapState(['reviewStates', 'asnStates', 'asnStatusTypes', 'qcStates',
            'adoptStatus'
        ]),
        getImgList() {
            return (imgList) => {
                return _.filter(imgList, (_, index) => index < 2)
            }
        }
    },
    methods: {
        formatListDataByKey,
        // 报废
        handleTipSubmit() {
            IOT.getServerData('/claim/master/cancel', 'post',
            {claimNo: this.currentRow.claimNo},
            (ret) => {
                if (ret.code === 200) {
                    IOT.tips('操作成功！', 'success');
                    this.getList()
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
            this.$refs.myTip.dialogVisible = false
        },
        deleteBtn(row) {
            this.currentRow = row
            this.tipObj.content = '确认报废该包裹产品吗？'
            this.$refs.myTip.dialogVisible = true
        },
        viewProduct(row) {
            this.$router.push({
                name: 'adoptClaimDetail',
                params: row,
            })
        },
        // 批量收货
        goToFastAcceptBtn() {
            const isSlectLength = _.filter(_.cloneDeep(this.multipleSelection), item => item.asnState === 0 || item.asnState===2 ).length
            if (isSlectLength !== this.multipleSelection.length) {
                this.$message.warning('只有待收货或者部分收货才能批量收货！')
                return
            }
            const billNo = _.map(_.cloneDeep(this.multipleSelection), item => item.billNo).join(',')
            this.$router.push({ name: 'fastAccept', params: { billNo }})
        },
        // 查询列表所有的数据
        getList() {
            var that = this;
            let searchData = PF.JSON(this.searchData);
            searchData.billNo = searchData.billNo ? searchData.billNo.split(/,|，|\s+/).join(',') : ''
            searchData.keyWord = searchData.keyWord ? searchData.keyWord.split(/,|，|\s+/).join(',') : undefined
            this.expandRowKeys = []

            let times = ['createTimeList']
            _.forEach(times, i => {
                if (searchData.selectTime !== i && searchData[i]) {
                    searchData[i] = []
                }
                if (searchData.selectTime === i && searchData[i] && searchData[i].length) {
                    searchData[i] = [searchData[i][0]+' 00:00:00', searchData[i][1]+' 23:59:59']
                }
            })
            that.loading.masterTable = true;
            IOT.getServerData('/claim/master/list', 'post', searchData, ret => {
                that.loading.masterTable = false;
                if (ret) {
                    let rows = ret.list;
                    this.asnBills = rows;
                    this.searchData.total = ret.total;
                    // this.searchData.pageNum = ret.pageNumber;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            }, true);
            that.$nextTick(() => {
                that.$refs.singleTable.doLayout(); // 解决表格错位
            });
        },
        // 详情
        toDetail(row) {
            this.$router.push({
                name: 'asnBillDetail',
                params: row,
            })
        },
        // 收货
        asnBillSub(row) {
            this.$router.push({
                name: 'fastAccept',
                params: row,
            })
        },
        // 单独点击展开触发
        handleExpandChange(row, expandList, type = 'item') {
            const obj = _.find(expandList, item => item.billNo === row.billNo)
            this.expandRowKeys = _.map(expandList || [], (it) => it.billNo);
            if (expandList.length > 0 && ((type === 'item' && obj) || type === 'all')) {
                let url = (url = '/asn/details/list');
                IOT.getServerData(url, 'post', { billNo: row.billNo }, ret => {
                    if (ret.code === 200) {
                        let list = ret.rows;
                        let deepAsnBills = _.cloneDeep(this.asnBills)
                        this.asnBills = _.map(deepAsnBills, i => {
                            if (i.billNo == row.billNo) {
                                i.details = list
                            }
                            return i
                        })
                    }
                });
            }
        },
        handleClick(tab, event) {
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                wareId: PF.getLocal('wareId'),
                wareCode: PF.getLocal('wareCode'),
                state: tab.name === 'all' ? null : tab.name,
                keyWord: '',
                selectTime: 'createTimeList'
            }
            this.carrierCode = null;
            this.activeName = tab.name
            // if (tab.name === 'all') {
            //     this.searchData.asnState = null
            // } else {
            //     this.searchData.asnState = tab.name
            // }
            this.getList()
        },
        pringAsnCode() {
            window.IOT.getServerData(
                '/asn/codes/print',
                'get',
                { wareId: this.wareId, quantity: this.asnPrintCode },
                ret => {
                    if (ret.code === 200) {
                        var strHTML = ret.data;
                        this.$parent.printHtml(strHTML, null);
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                },
                true
            );
        },
        isDelete(asnMasterId) {
            this.$confirm('确认关闭吗？（不会删除）')
                .then(() => {
                    this.deleteOrder(asnMasterId);
                })
                .catch(() => {});
        },
        deleteOrder(asnMasterId) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/masters/state6', 'get', { id: asnMasterId }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        isDeleteDetail(id) {
            this.$confirm('确认关闭吗？ ')
                .then(() => {
                    this.deleteAsnDetail(id);
                })
                .catch(() => {});
        },
        deleteAsnDetail(id) {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/delete', 'get', { id: id }, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('删除成功！', 'success');
                    this.getList();
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },

        allotAsn() {
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData('/asn/details/allotAsn', 'get', {}, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                } else {
                    window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        noShipCommit() {
            var noShipInfo = this.noShipInfo;

            window.IOT.showOverlay('提交中～～～');
            IOT.getServerData('/no/ships/add', 'post', noShipInfo, ret => {
                window.IOT.hideOverlay();
                if (ret.code === 200) {
                    window.IOT.tips('提交成功！', 'success');
                    this.noShipVisible = false;
                    this.noShipInfo = {};
                } else {
                }
            });
        },
        getAsnDetailData(billNo) {
            let url = (url = '/asn/details/list');
            IOT.getServerData(url, 'post', { billNo: billNo }, ret => {
                if (ret.code === 200) {
                    let list = ret.rows;
                    this.asnBills.forEach(order => {
                        if (order.billNo == billNo) {
                            order.details = list;
                        }
                    });
                    var asnBills = JSON.parse(JSON.stringify(this.asnBills));
                    this.asnBills = [];
                    this.asnBills = asnBills;
                } else {
                }
            });
        },
        commitMemo() {
            var that = this;
            that.memoVisible = false;
            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/memo',
                'get',
                {
                    asnMasterId: that.memoData.asnMasterId,
                    memo: that.memoData.memo,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },

        getWlgj(row) {
            window.IOT.getServerData(
                '/kuaidiniaos/jscx',
                'get',
                {
                    expCode: row.carrierCode,
                    expNo: row.shipBillCode,
                },
                ret => {
                    debugger;
                    window.IOT.hideOverlay();
                },
                true
            );
        },
        updateShip(asn) {
            var that = this;
            that.rowData = asn;
            that.shipFormVisible = true;
        },
        commitShip() {
            var that = this;

            window.IOT.showOverlay('提交中～～～');
            window.IOT.getServerData(
                '/asn/masters/addShipBillCode',
                'post',
                {
                    billNo: that.rowData.billNo,
                    asnMasterId: that.rowData.asnMasterId,
                    shipBillNo: that.rowData.shipBillCode,
                    shipCode: that.rowData.carrierCode,
                },
                ret => {
                    window.IOT.hideOverlay();
                    if (ret.code === 200) {
                        window.IOT.tips('提交成功！', 'success');
                        this.getList();
                    } else {
                        window.IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },
        handleSizeChange(val) {
            this.searchData.pageSize = val;
            this.getList();
        },

        handleCurrentChange(val) {
            this.searchData.pageNum = val;
            this.getList();
        },
        clearSearchData() {
            this.searchData = {
                pageNum: 1,
                pageSize: 15,
                wareId: PF.getLocal('wareId'),
                wareCode: PF.getLocal('wareCode'),
                state: null,
                keyWord: ''
            }
            this.carrierCode = null;
            this.getList()
        },
        getPlatformData() {
            IOT.getServerData('/platforms/findByWareId', 'get', { wareId: PF.getLocal('wareId', 'number') }, ret => {
                if (ret.code === 200) {
                    this.platforms = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCarrierData() {
            IOT.getServerData('/carriers/allList', 'get', {}, ret => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        commitFile() {
            IOT.showOverlay('保存中，请稍等...');
            let list = PF.JSON(this.multipleSelection);
            let billNos = [];
            for (let i = 0; i < list.length; i++) {
                billNos.push(list[i].billNo);
            }
            IOT.getServerData('/asn/masters/file', 'post', { billNos: billNos }, ret => {
                IOT.hideOverlay();
                this.multipleSelection = [];
                if (ret.code === 200) {
                    IOT.tips('归档成功！', 'success');
                    this.getList();
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        getCode() {
            var that = this;
            document.onkeypress = function(e) {
                that.getList();
            };
        },
    },
    created() {
        this.getList();
        this.getPlatformData();
        this.getCarrierData();
        PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
            this.asnUdfHs = udfs;
        });
        PF.getOrganizations(rows => {
            this.organizations = rows;
        });
        PF.getSuppliers(rows => {
            this.suppliers = rows;
        });
        PF.getDataDictUdfData('asnType', rows => {
            this.asnTypes = rows;
        });
        PF.getCarriers(rows => {
            this.carriers = rows;
        });
        //   this.getCode();
    },
    mounted() {
        this.commonSearchRef = this.$refs.commonSearchRef
    },
};
</script>

<style scoped lang="less">
.asnBill {
}
</style>
