<template>
    <div class="vp-tooltip">
        <el-tooltip
            effect="dark"
            :content="text"
            placement="top"
            popper-class="custom-tooltip"
            :disabled="showTooltip"
        >
            <div @mouseenter="mouseEnter" :key="text">
                {{ (text || text === 0) ? text : '--' }}
            </div>
        </el-tooltip>
        <!-- <el-tooltip class="item"  :content="item.itemName" placement="top-start">
            <div class="text no-wrap" style="width: 500px;">
            {{ item.itemName||'-' }}
            </div>
        </el-tooltip> -->
    </div>
</template>

<script>
export default {
    name: 'Zhihui-Tooltip',
    props: {
        text: {
            type: [String, Number]
        },
        placement: {
            type: String,
            default: () => {
                return "top"
            }
        }
    },
    data(){
        return {
            showTooltip: true
        }
    },
    methods: {
        mouseEnter(event) {
            let element = event.target;
            console.log(element.scrollWidth <= element.clientWidth, 'event');

            this.showTooltip = element.scrollWidth <= element.clientWidth;
        },
        copyTextSuccess() {

        }
    },
    mounted() {
    }
}
</script>

<style lang="less">
.custom-tooltip .el-tooltip__popper {
  max-width: 200px !important; /* 设置 Tooltip 的最大宽度 */
}
</style>